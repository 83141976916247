import React, { FC } from 'react';
import { css, cx } from '@emotion/css';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/favicon.png" alt="Grafana" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css`
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: url(public/img/g8_login_dark.png);
      background-position: top center;
      background-size: auto;
      background-repeat: repeat;
      background-color: white;
      opacity: 0;
      transition: opacity 3s ease-in-out;
    }
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/favicon.png" alt="Grafana" />;
};

const LoginBoxBackground = () => {
  return css`
    background-color: #f2f4f8;
    background-size: cover;
  `;
};

const LoginStyle = () => {
  return css`
    color: #5e5e5e;
    background-size: cover;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'Kronnika Analytics';
  static LoginTitle = 'Welcome to';
  static LoginStyle = LoginStyle();
  static GetLoginSubTitle = (): null | string => {
    return null;
  };
}
